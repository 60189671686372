import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { Note } from '@phosphor-icons/react';

import RichTextEditor from 'components/shared/RichTextEditor';
import SummaryField from 'components/shared/SummaryField';
import { EventDetailsFormControl } from 'types/EventDetailsForm';

interface EventDescriptionFieldProps {
  control: EventDetailsFormControl;
  defaultValue: string | undefined;
  disableFields: boolean;
  isLoading?: boolean;
}

const EventDescriptionField = ({
  control,
  defaultValue,
  disableFields,
  isLoading = false,
}: EventDescriptionFieldProps) => {
  const [pendingValue, setPendingValue] = useState(defaultValue);

  return (
    <Controller
      control={control}
      name='description'
      render={({ field }) => {
        const hasChanges = field.value !== pendingValue;
        return (
          <SummaryField
            component={
              <RichTextEditor
                onChange={(val) => setPendingValue(val)}
                value={pendingValue}
              />
            }
            hasChanges={hasChanges}
            Icon={Note}
            isEditable={!disableFields}
            isLoading={isLoading}
            onCancel={() => setPendingValue(field.value || '')}
            onSubmit={() => {
              field.onChange(pendingValue);
            }}
            primary='Description'
            secondary={`Let guests know about guest speakers, agenda items, and what else 
                  they can look forward to at your upcoming event!`}
            type='dialog'
            typeProps={{ maxWidth: 'md' }}
            value={pendingValue}
            valueType='checkmark-boolean'
          />
        );
      }}
    />
  );
};

export default EventDescriptionField;

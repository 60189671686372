import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { CircularProgress, Typography } from '@mui/material';

import CollectionCard from 'components/Collections/CollectionCard';
import GridCards from 'components/shared/GridCards';
import { useFetchEventCollections } from 'hooks/useFetchEventCollections';
import { useInfiniteScrollQuery } from 'hooks/useInfiniteScrollQuery';

interface Collection {
  description: string;
  endsAt: string;
  events: { id: string }[];
  id: string;
  name: string;
  organization: { id: string };
  published: boolean;
  startsAt: string;
  timezone: string;
  user: { id: string };
}

interface CollectionsResponse {
  currentPage: number;
  eventsCollections: Collection[];
  totalCount: number;
  totalPages: number;
}

const CollectionsTab = ({ filters, type }: { filters: any; type: string }) => {
  const observerTarget = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();

  const { data, hasNextPage, isError, isFetchingNextPage } =
    useInfiniteScrollQuery<CollectionsResponse>({
      key: ['collections', filters, type],
      observerTarget,
      useQuery: async ({ pageParam = 1 }) => {
        const response = await useFetchEventCollections({
          organizerIds: filters?.organizers,
          page: pageParam,
          perPage: 12,
          search: filters?.search,
          status: type,
        });

        const hasNextPage = response.currentPage < response.totalPages;

        return { ...response, hasNextPage };
      },
    });

  const allCollections =
    data?.pages.flatMap((page: any) => page.eventsCollections || []) || [];

  const handleOpenCollection = (collection: Collection) => {
    const slug = collection.published ? 'details' : 'create';
    navigate(`/collections/${collection.id}/${slug}`);
  };

  return isError ? (
    <Typography color='error'>Something went wrong.</Typography>
  ) : (
    <div>
      <GridCards
        CardComponent={(item: any) => (
          <CollectionCard
            handleOpenCard={() => handleOpenCollection(item)}
            item={item}
          />
        )}
        chunk={{ values: allCollections }}
        noun='collections'
      />
      <div ref={observerTarget} style={{ height: '10px' }}>
        {/* if this doesn't work well consider switching to a Load More button? */}
        {(!data || (isFetchingNextPage && hasNextPage)) && <CircularProgress />}
      </div>

      {allCollections.length > 0 && !hasNextPage && !isFetchingNextPage && (
        <div>You have reached the end of the collections.</div>
      )}
    </div>
  );
};

export default CollectionsTab;

import React, { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  alpha,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { Check, MinusCircle, UserCirclePlus } from '@phosphor-icons/react';

import { InvitationFormData } from 'components/Events/Controls/Communications/communication.types';
import UniqueGuestCount from 'components/Events/Controls/Shared/UniqueGuestCount';
import SlackChannelLabel from 'components/shared/SlackChannelLabel';
import TabLabelWithCount from 'components/shared/TabLabelWithCount';
import { eventInvitationGuestTypeLabelsById } from 'constants/event.constants';
import { EventInvitationGuestTypeId } from 'types/EventInvitationGuestType';
import { flexColumn } from 'utils/styles';

interface Props {
  isSelectable: boolean;
  nounPlural: 'channels' | 'guests';
}

const SelectedGuests = ({ isSelectable, nounPlural }: Props) => {
  const form = useFormContext<InvitationFormData>();

  const [isConfirmingDeselect, setIsConfirmingDeselect] = useState(false);

  const theme = useTheme();

  const isSlack = useMemo(() => nounPlural === 'channels', [nounPlural]);

  const handleChange = (newValues: any, type: EventInvitationGuestTypeId) => {
    form.setValue(`selections.${type}`, newValues);
    form.setValue(
      `rowSelection.${type}`,
      Object.fromEntries(
        newValues?.map((v: { id: any }) => [String(v.id), true])
      )
    );
  };

  const selections = form.watch(`selections`);

  return (
    <>
      <Box flexGrow={1} height='calc(100vh - 240px)' position='sticky' top={0}>
        <Box
          display='flex'
          flexDirection='column'
          height='100%'
          sx={{ overflowY: 'auto' }}
        >
          {Object.values(selections || []).some((s) => s?.length > 0) ? (
            <>
              <UniqueGuestCount />
              <List
                data-testid='selected-guests'
                dense
                disablePadding
                sx={{
                  ...flexColumn,
                  gap: 1.5,
                  mt: -1.5, // offset 1st ListSubheader padding-top
                }}
              >
                {Object.entries(selections || [])
                  ?.filter(([type]) =>
                    isSlack ? type === 'channel' : type !== 'channel'
                  )
                  ?.map(([type, typeSelections]) => {
                    const sectionTitle =
                      eventInvitationGuestTypeLabelsById[type];
                    if (type && type.length > 0 && typeSelections?.length > 0) {
                      return (
                        <React.Fragment key={type}>
                          <ListSubheader
                            sx={{
                              lineHeight: 1.5,
                              // so when you scroll, the subheader isn't right up against the card edge
                              pb: 1,
                              pt: 1.5, // so when you scroll, the subheader isn't right up against the scroll content
                              typography: 'body2',
                            }}
                          >
                            {isSlack ? (
                              <TabLabelWithCount
                                count={typeSelections?.length}
                                label={String(sectionTitle || '')}
                              />
                            ) : (
                              sectionTitle
                            )}
                          </ListSubheader>
                          {typeSelections
                            // ?.sort((a: { name: string }, b: { name: string }) =>
                            //   a.name.localeCompare(b.name)
                            // )
                            ?.map((opt: any, itemIndex: number) => {
                              if (opt?.id) {
                                const labelId = `checkbox-list-label-${opt.id}`;
                                return (
                                  <ListItem
                                    disableGutters
                                    key={opt?.id}
                                    sx={{
                                      '& .MuiTouchRipple-child': {
                                        backgroundColor: alpha(
                                          theme.palette.secondary.main,
                                          0.5
                                        ),
                                      },
                                      alignItems:
                                        type === 'contact'
                                          ? 'flex-start'
                                          : 'center',
                                      mt: itemIndex === 0 ? -1 : 0,
                                      // offset the extra space rendered below the subheader
                                      pb: 0,
                                      pt: 0,
                                    }}
                                  >
                                    <ListItemButton
                                      onClick={() => {
                                        const newVals = typeSelections?.filter(
                                          (v: any) => v.id !== opt.id
                                        );
                                        handleChange(
                                          newVals,
                                          type as EventInvitationGuestTypeId
                                        );
                                      }}
                                      role={undefined}
                                    >
                                      <ListItemIcon
                                        sx={{
                                          minWidth: isSelectable ? 'unset' : 36,
                                          mt: type === 'contact' ? -2 : 0,
                                          // INFO: this offsets the padding around the checkbox
                                          //       to align it with contact name (first line)
                                          width: 32,
                                        }}
                                      >
                                        {isSelectable ? (
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={typeSelections
                                                  ?.map((item: any) => item?.id)
                                                  ?.includes(opt?.id)}
                                                color='secondary'
                                                data-testid='selected-guest-checkbox'
                                                disableRipple
                                                inputProps={{
                                                  'aria-labelledby': labelId,
                                                }}
                                                tabIndex={-1}
                                              />
                                            }
                                            label={null}
                                            sx={{ ml: -0.5 }}
                                          />
                                        ) : (
                                          <Check size={24} />
                                        )}
                                      </ListItemIcon>
                                      <ListItemText
                                        id={labelId}
                                        primary={
                                          isSlack ? (
                                            <SlackChannelLabel
                                              isPrivate={opt?.private}
                                              name={opt?.name}
                                            />
                                          ) : (
                                            opt?.name
                                          )
                                        }
                                        secondary={opt?.email || null}
                                        secondaryTypographyProps={{
                                          color: 'text.secondary',
                                          sx: { overflow: 'hidden' },
                                          textOverflow: 'ellipsis',
                                          variant: 'overline',
                                        }}
                                        sx={{ m: 0 }}
                                      />
                                    </ListItemButton>
                                  </ListItem>
                                );
                              }
                              return null;
                            })}
                        </React.Fragment>
                      );
                    } else {
                      return null;
                    }
                  })}
              </List>
              {isSelectable ? (
                <Box
                  bgcolor='white'
                  bottom={0}
                  flexGrow={1}
                  onClick={(e) => e.preventDefault()}
                  position='sticky'
                >
                  <Button
                    onClick={() => setIsConfirmingDeselect(true)}
                    startIcon={<MinusCircle />}
                    sx={{ mx: 1.5, my: 1 }}
                  >
                    Deselect all
                  </Button>
                </Box>
              ) : null}
            </>
          ) : (
            <>
              <Typography m={2} mt={2.25} variant='h4'>
                {/* INFO: mt={2.25} aligns this text with the text + chip that replaces it when there are selections. 
                            Once event-comms is live, this will no longer be needed, as we can replace the ButtonTabs 
                            with an actual CardHeader, and the UniqueGuestCount component will only be used here. */}
                Selected
              </Typography>
              <Box
                alignItems='center'
                color='text.secondary'
                display='flex'
                flexDirection='column'
                flexGrow={1}
                justifyContent='center'
                p={2}
              >
                <Stack
                  alignItems='center'
                  direction='column'
                  gap={1}
                  textAlign='center'
                >
                  <UserCirclePlus
                    color={theme.palette.secondary.main}
                    size={40}
                  />
                  <Typography color='grey.700' variant='body2'>
                    No {nounPlural} selected yet
                  </Typography>
                  <Typography color='grey.600' variant='body1'>
                    Select the {nounPlural} you want to invite to this event
                    {isSelectable ? '' : ', then click "Send invitations"'}.
                  </Typography>
                </Stack>
              </Box>
            </>
          )}
        </Box>
      </Box>
      <Dialog
        onClose={() => setIsConfirmingDeselect(false)}
        open={isConfirmingDeselect}
      >
        <DialogTitle>Are you sure you want to deselect all guests?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This will result in the removal of all currently selected guests.
            You will still be able to reselect all desired guests manually.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsConfirmingDeselect(false)} variant='text'>
            Cancel
          </Button>
          <Button
            onClick={() => {
              form.setValue('selections', {
                channel: [],
                contact: [],
                group: [],
                list: [],
                office: [],
              });
              form.setValue('rowSelection', {
                channel: {},
                contact: {},
                group: {},
                list: {},
                office: {},
              });
              form.setValue('selectedGuestIds', new Set());
              setIsConfirmingDeselect(false);
            }}
            variant='contained'
          >
            Yes, deselect all
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SelectedGuests;

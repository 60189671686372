import React, { useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Button, Card, CardContent, Typography } from '@mui/material';
import {
  CalendarPlus,
  Gear,
  Note,
  PlusCircle,
  Sparkle,
} from '@phosphor-icons/react';

import AddEventsDialog from 'components/Collections/AddEventsDialog';
import CollectionBannerImageField from 'components/Collections/CollectionBannerImageField';
import CollectionDescriptionField from 'components/Collections/CollectionDescriptionField';
import CollectionOrganizerField from 'components/Collections/CollectionOrganizerField';
import CollectionTitleField from 'components/Collections/CollectionTitleField';
import SelectedEventsList from 'components/Collections/SelectedEventsList';
import FormSection from 'components/shared/FormSection';
import SummaryFieldsWrapper from 'components/shared/SummaryFieldsWrapper';
import { CollectionFormData, Event } from 'types/CollectionForm';

interface CollectionFormProps {
  collectionId?: string | null;
  disableFields?: boolean;
  hookForm: UseFormReturn<CollectionFormData>;
  isLoading?: boolean;
  isPublished?: boolean;
}

const CollectionForm = ({
  collectionId,
  disableFields = false,
  hookForm,
  isLoading,
  isPublished,
}: CollectionFormProps) => {
  const [isEventDialogOpen, setIsEventDialogOpen] = useState(false);

  const commonProps = {
    disableFields,
    isLoading,
    ...hookForm,
  };

  const handleSaveSelectedEvents = (events: Event[]) => {
    setIsEventDialogOpen(false);
    hookForm.setValue('events', events, { shouldDirty: true }); // Update the form state with selected events
  };

  const handleRemoveEvent = (eventId: number) => {
    const currentEvents = hookForm.getValues('events') || [];
    const updatedEvents = currentEvents.filter((event) => event.id !== eventId);
    hookForm.setValue('events', updatedEvents, { shouldDirty: true });
  };

  const selectedEvents = hookForm.watch('events') || []; // Watch for changes in the 'events' field

  const { error } = hookForm.getFieldState('events', hookForm.formState);

  return (
    <Card>
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 5,
          px: 3,
          py: 4,
        }}
      >
        <FormSection
          description='Generate guest interest with a catchy title and a captivating image!'
          fields={
            <>
              <CollectionTitleField {...commonProps} />
              <CollectionBannerImageField
                isPublished={isPublished}
                {...commonProps}
              />
            </>
          }
          Icon={Sparkle}
          title='Collection basics'
        />
        <FormSection
          description='Write a short description for your collection'
          fields={<CollectionDescriptionField {...commonProps} />}
          Icon={Note}
          title='Description'
        />
        <FormSection
          description='Add events to your collection'
          fields={
            <>
              <SelectedEventsList
                {...commonProps}
                events={selectedEvents}
                onRemoveEvent={handleRemoveEvent}
              />
              <Button
                fullWidth
                onClick={() => {
                  setIsEventDialogOpen(true);
                }}
                startIcon={<PlusCircle />}
                variant='contained'
              >
                Add events
              </Button>
              {error && (
                <Typography color='error' variant='body2'>
                  {error.message}
                </Typography>
              )}
            </>
          }
          Icon={CalendarPlus}
          title='Events'
        />
        <FormSection
          description='Manage the settings for your collection'
          fields={
            <SummaryFieldsWrapper>
              <CollectionOrganizerField {...commonProps} />
            </SummaryFieldsWrapper>
          }
          Icon={Gear}
          title='Settings'
        />
      </CardContent>
      <AddEventsDialog
        {...commonProps}
        collectionId={collectionId}
        initialSelectedEvents={selectedEvents}
        onClose={() => setIsEventDialogOpen(false)}
        onSave={handleSaveSelectedEvents}
        open={isEventDialogOpen}
      />
    </Card>
  );
};

export default CollectionForm;

import { gql } from 'graphql-request';

import { useGraphQLClient } from 'hooks/useGraphQLClient';

interface FetchEventsCollectionsResponse {
  fetchEventsCollections: {
    currentPage: number;
    eventsCollections: {
      description: string;
      endsAt: string;
      events: {
        id: string;
      }[];
      id: string;
      imageKey: string;
      imageUrl: string;
      name: string;
      organization: {
        id: string;
      };
      published: boolean;
      startsAt: string;
      timezone: string;
      user: {
        id: string;
      };
    }[];
    totalCount: number;
    totalPages: number;
  };
}

const fetchEventsCollectionsQuery = gql`
  query fetchEventsCollections(
    $page: Int!
    $perPage: Int!
    $search: String
    $status: String
    $organizerIds: [ID!]
  ) {
    fetchEventsCollections(
      page: $page
      perPage: $perPage
      search: $search
      status: $status
      organizerIds: $organizerIds
    ) {
      currentPage
      totalPages
      totalCount
      eventsCollections {
        id
        name
        description
        safeDescription
        startsAt
        imageKey
        imageUrl
        endsAt
        published
        timezone
        organization {
          id
        }
        user {
          id
        }
        events {
          id
        }
      }
    }
  }
`;

export const useFetchEventCollections = async ({
  organizerIds,
  page,
  perPage,
  search,
  status,
}: {
  organizerIds?: string[];
  page: number;
  perPage: number;
  search?: string;
  status?: string;
}) => {
  const graphQLClient = useGraphQLClient();

  const response: FetchEventsCollectionsResponse = await graphQLClient.request(
    fetchEventsCollectionsQuery,
    {
      organizerIds,
      page,
      perPage,
      search,
      status,
    }
  );

  return response.fetchEventsCollections;
};

import { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { EventBudget } from 'types/EventBudget';
import { reactQueryAxios } from 'utils/axios';

type GetEventBudgetParams = {
  eventId: number;
};

interface Response {
  data: EventBudget;
}

interface CustomError {
  data: {
    message: string;
  };
  message: string;
  status: number;
}

interface UpdateEventBudgetParams {
  amount?: number;
  currency_id?: string;
  group_ids?: number[] | string;
}

export const useEventBudget = ({ eventId }: GetEventBudgetParams) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const url = `/api/event_budgets?event_id=${eventId}`;

  const queryKey = useMemo(() => ['event', `${eventId}`, 'budget'], [eventId]);

  const getEventBudget = async ({
    apiClient,
    eventId,
  }: GetEventBudgetParams & { apiClient: any }) =>
    apiClient({
      method: 'get',
      url: `/api/events/${eventId}/event_budget`,
    });

  const retrieve = useQuery({
    queryFn: () =>
      getEventBudget({ apiClient: reactQueryAxios, eventId }).then(
        (response: Response) => response.data
      ),
    queryKey,
  });

  const update = useMutation({
    mutationFn: async (data: UpdateEventBudgetParams) => {
      await queryClient.cancelQueries({
        queryKey,
      });
      return reactQueryAxios({
        data: { event_budget: data },
        method: 'patch',
        url,
      });
    },
    onError: (error: CustomError) => {
      enqueueSnackbar(
        `Failed to save event budget: ${error.data.message || error.message}`,
        {
          variant: 'error',
        }
      );
    },
    onSuccess: (response) => {
      queryClient.setQueryData(queryKey, response.data.event_budget);
      enqueueSnackbar('Event budget saved!', { variant: 'success' });
    },
  });

  const remove = useMutation({
    mutationFn: async () => {
      await queryClient.cancelQueries({
        queryKey,
      });
      return reactQueryAxios({
        method: 'delete',
        url,
      });
    },

    onSuccess: (response) => {
      queryClient.setQueryData(queryKey, response.data);
      enqueueSnackbar('Event budget deleted', { variant: 'success' });
    },
  });

  return { remove, retrieve, update };
};

import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Stack, Typography } from '@mui/material';
import { AddressBook, CalendarPlus } from '@phosphor-icons/react';

import { communicationTypeDetails } from 'components/Events/Controls/Communications/communication.constants';
import CommunicationMethodMenu from 'components/Events/Controls/Communications/CommunicationMethodMenu';
import { ManagerContext } from 'components/Events/Manager/ManagerContext';

const EventGuestsEmptyState = (props) => {
  const {
    state: { event, manageTabs },
  } = useContext(ManagerContext);
  const navigate = useNavigate();

  return (
    <Stack
      alignItems='center'
      direction='column'
      gap={0.5}
      height='100%'
      justifyContent='flex-start'
      sx={{ pt: 4 }}
      {...props}
    >
      <Box color='secondary'>
        <AddressBook size={32} />
      </Box>
      <Typography gutterBottom mb={0.5}>
        Your guest list is empty
      </Typography>
      <Typography color='text.secondary' mb={2.25} variant='overline'>
        Start adding guests to your event by sending invitations!
      </Typography>
      <CommunicationMethodMenu
        buttonLabel='Invite guests'
        type={communicationTypeDetails['invitation']}
      />
      <Button
        onClick={() =>
          navigate(`/events/${event.id}/${manageTabs.communications.slug}`)
        }
        startIcon={<CalendarPlus />}
        sx={{ display: 'none', px: 4 }}
        variant='contained'
      >
        Invite guests
      </Button>
    </Stack>
  );
};

export default EventGuestsEmptyState;

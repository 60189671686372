import React, { useMemo } from 'react';
import CacheBuster from 'react-cache-buster';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import 'typeface-inter';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { GoogleOAuthProvider } from '@react-oauth/google';
import * as Sentry from '@sentry/react';
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useFlag } from '@unleash/proxy-client-react';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { useSnackbar } from 'notistack';

import Analytics from 'components/Analytics/Analytics';
import AuthOktaSaml from 'components/AuthOktaSaml';
import Collection from 'components/Collections/Collection';
import CollectionCreate from 'components/Collections/CollectionCreate';
import CollectionManager from 'components/Collections/CollectionManager';
import Collections from 'components/Collections/Collections';
import Contacts from 'components/Contacts/Contacts';
import { FullscreenLoader } from 'components/Core/Loader';
import EventCreate from 'components/Event/EventCreate';
import EventLoader from 'components/Event/EventLoader';
import EditorWrapper from 'components/Events/Editor/EditorWrapper';
import Events from 'components/Events/Events';
import EventManager from 'components/Events/Manager/EventManager';
import PublicEvent from 'components/Events/PublicEvent/PublicEvent';
import Group from 'components/Groups/Group';
import GroupCreationPage from 'components/Groups/GroupCreationPage';
import Groups from 'components/Groups/Groups';
import AdminLogin from 'components/Onboard/AdminLogin';
import ChangePassword from 'components/Onboard/ChangePassword';
import Login from 'components/Onboard/Login';
import OktaLoginRedirector from 'components/Onboard/OktaLoginRedirector';
import OutlookLoginRedirector from 'components/Onboard/OutlookLoginRedirector';
import ResetPassword from 'components/Onboard/ResetPassword';
import SignUp from 'components/Onboard/SignUp';
import TokenAccept from 'components/Onboard/TokenAccept';
import WelcomeMessage from 'components/Onboard/WelcomeMessage';
import Profile from 'components/Profile/Profile';
import EventRedirector from 'components/Public/PublicGcalEvent/EventRedirector';
import SlackIntegration from 'components/Settings/Integrations/Slack/SlackIntegration';
import Settings from 'components/Settings/Settings';
import AppLayout from 'components/shared/AppLayout';
import AudioEye from 'components/shared/AudioEye';
import HotJar from 'components/shared/Hotjar';
import OktaCludge from 'components/shared/OktaCludge';
import AuthenticatedRoute from 'components/shared/routes/AuthenticatedRoute';
import ProtectedRoute from 'components/shared/routes/ProtectedRoute';
import Maintenance from 'components/shared/views/Maintenance';
import NotFound from 'components/shared/views/NotFound';
import ZoomRedirect from 'components/shared/ZoomRedirect';
import Survey from 'components/Survey/Survey';
import Surveys from 'components/Survey/Surveys';
import SurveyTemplateForm from 'components/Survey/SurveyTemplates/SurveyTemplateForm';
import { AnalyticsProvider } from 'contexts/AnalyticsContext';
import { AuthProvider } from 'contexts/AuthContext';
import { EmployeesProvider } from 'contexts/EmployeesContext';
import { GroupProvider } from 'contexts/GroupContext';
import { GroupsProvider } from 'contexts/GroupsContext';
import { ListsProvider } from 'contexts/ListsContext';
import { OrganizationProvider } from 'contexts/OrganizationContext';
import { PublicOrgProvider } from 'contexts/PublicOrgContext';
import { SettingsProvider } from 'contexts/SettingsContext';
import { SlackChannelsProvider } from 'contexts/SlackChannelsContext';
import { SurveyTemplatesProvider } from 'contexts/SurveyTemplatesContext';
import { SyncedSlackChannelProvider } from 'contexts/SyncedSlackChannelContext';
import { dayjs } from 'utils/dayjs';

dayjs.extend(advancedFormat);
dayjs.tz.setDefault('America/New_York');

const App = () => {
  const isInMaintenanceMode = useFlag('MaintenanceMode');
  const isProduction = import.meta.env.NODE_ENV === 'production';
  const { enqueueSnackbar } = useSnackbar();

  const queryClient = new QueryClient({
    queryCache: new QueryCache({
      onError: (error, query) => {
        if (import.meta.env.DEV) {
          console.error(error);
        }
        if (query?.meta?.errorMessage) {
          enqueueSnackbar(String(query?.meta?.errorMessage), {
            variant: 'error',
          });
        }
      },
    }),
  });

  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

  const cacheBusterLoader = useMemo(
    () => (
      <FullscreenLoader
        message='Checking for a new version of Five to Nine'
        show
      />
    ),
    []
  );

  const googleCalendarClientId = import.meta.env.VITE_GOOGLE_CALENDAR_CLIENT_ID;

  return (
    <CacheBuster
      currentVersion={import.meta.env.VITE_VERSION}
      isEnabled={isProduction} // If false, the library is disabled.
      isVerboseMode={false} // If true, the library writes verbose logs to console.
      loadingComponent={cacheBusterLoader} // If not pass, nothing appears at the time of new version check.
    >
      {isInMaintenanceMode ? (
        <Maintenance />
      ) : (
        <QueryClientProvider client={queryClient}>
          <SentryRoutes>
            <Route Component={AuthOktaSaml} path='/auth/okta/saml/*' />
            <Route
              element={
                <OktaCludge>
                  <AuthProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <PublicOrgProvider>
                        <HotJar />
                        <AudioEye />
                        <OrganizationProvider>
                          <EmployeesProvider>
                            <WelcomeMessage />
                            <GroupsProvider>
                              <GroupProvider>
                                <SettingsProvider>
                                  <ListsProvider>
                                    <AnalyticsProvider>
                                      <GoogleOAuthProvider
                                        clientId={googleCalendarClientId}
                                      >
                                        <SurveyTemplatesProvider>
                                          <Outlet />
                                        </SurveyTemplatesProvider>
                                      </GoogleOAuthProvider>
                                    </AnalyticsProvider>
                                  </ListsProvider>
                                </SettingsProvider>
                              </GroupProvider>
                            </GroupsProvider>
                          </EmployeesProvider>
                        </OrganizationProvider>
                      </PublicOrgProvider>
                    </LocalizationProvider>
                  </AuthProvider>
                </OktaCludge>
              }
              path='*'
            >
              <>
                <Route element={<SignUp />} path='sign-up' />

                <Route element={<AdminLogin />} path='admin/sign-up' />
                <Route element={<Login />} path='login' />

                <Route element={<ResetPassword />} path='reset-password' />
                <Route element={<ChangePassword />} path='change-password' />

                <Route element={<OktaLoginRedirector />} path='auth/okta' />
                <Route
                  element={<OutlookLoginRedirector />}
                  path='auth/outlook'
                />
                <Route element={<TokenAccept />} path='wait-for-token' />
                <Route element={<ZoomRedirect />} path='zoom/callback' />

                <Route
                  element={<Survey type='pre_event_survey' />}
                  path='survey/:id/pre'
                />
                <Route
                  element={<Survey type='post_event_survey' />}
                  path='survey/:id/post'
                />

                <Route element={<PublicEvent />} path='public/:id' />
                <Route element={<EventRedirector />} path='ext_calendar/:id' />

                <Route
                  element={
                    <AuthenticatedRoute>
                      <AppLayout>
                        <SlackChannelsProvider>
                          <SyncedSlackChannelProvider>
                            <Outlet />
                          </SyncedSlackChannelProvider>
                        </SlackChannelsProvider>
                      </AppLayout>
                    </AuthenticatedRoute>
                  }
                  path='*'
                >
                  <>
                    <Route
                      element={
                        <ProtectedRoute minRoleRequired='organizer'>
                          <EditorWrapper>
                            <Outlet />
                          </EditorWrapper>
                        </ProtectedRoute>
                      }
                      path='event'
                    >
                      <Route element={<EventCreate />} path='new/create' />
                      <Route element={<EventLoader />} path=':id/create' />
                      <Route
                        element={<EventManager />}
                        path=':id/:tab?/:type?/:method?/:commId?'
                      />
                      <Route element={<NotFound />} path='*' />
                    </Route>

                    <Route element={<Events />} path='events/:tab?' />

                    <Route element={<Groups />} path='groups/:tab?' />
                    <Route path='group'>
                      <Route element={<Group />} path=':id/:tab?' />
                      <Route
                        element={
                          <ProtectedRoute minRoleRequired='organizer'>
                            <GroupCreationPage />
                          </ProtectedRoute>
                        }
                        path='new'
                      />
                    </Route>
                    <Route element={<Profile />} path='profile/:tab?' />

                    <Route element={<Navigate replace to='/events' />} index />
                    <Route element={<NotFound />} path='*' />

                    {/* ORGANIZER ONLY ---------- */}
                    <Route
                      element={<ProtectedRoute minRoleRequired='organizer' />}
                    >
                      <Route path='collections'>
                        <Route element={<CollectionCreate />} path='create' />
                        <Route
                          element={<CollectionManager />}
                          path=':id/create'
                        />
                        <Route
                          element={<CollectionManager />}
                          path=':id/edit'
                        />
                        <Route element={<Collection />} path=':id/details' />
                        <Route element={<Collections />} path=':tab?' />
                      </Route>
                      <Route element={<Contacts />} path='contacts/:tab?' />
                      <Route element={<Analytics />} path='analytics' />
                      <Route
                        element={<Navigate replace to='/surveyTemplates' />}
                        path='surveys'
                      />
                      <Route
                        element={<Surveys />}
                        path='surveyTemplates/:tab?'
                      />
                      <Route
                        element={<SurveyTemplateForm />}
                        path='surveyTemplate/:id/edit'
                      />
                    </Route>

                    {/* ADMIN ONLY ---------- */}
                    <Route element={<ProtectedRoute minRoleRequired='admin' />}>
                      <Route path='settings/:tab?'>
                        <Route
                          element={<SlackIntegration />}
                          path='slack/:slackTab'
                        />
                        <Route element={<Settings />} index />
                      </Route>
                    </Route>
                  </>
                </Route>
              </>
            </Route>
          </SentryRoutes>
          {import.meta.env.VITE_FLAG_REACT_QUERY ? (
            <ReactQueryDevtools initialIsOpen={false} />
          ) : null}
        </QueryClientProvider>
      )}
    </CacheBuster>
  );
};

export default App;

import React, { useRef, useState } from 'react';
import Cropper, { ReactCropperElement } from 'react-cropper';
import { Controller } from 'react-hook-form';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormLabel,
  Stack,
} from '@mui/material';
import { Image, NotePencil, Trash } from '@phosphor-icons/react';

import FileUploader from 'components/shared/FileUploader';
import ImageFieldButton from 'components/shared/ImageFieldButton';

import 'cropperjs/dist/cropper.css';

export interface ImageFieldValue {
  cropDataUrl?: string;
  deleteImage?: boolean;
  dimensions?: {
    height: number;
    width: number;
    x: number;
    y: number;
  };
  file: File;
  fullDataUrl?: string;
}

interface ImageFieldProps {
  control: any;
  id: string;
  isDisabled: boolean;
  label: string;
  name: string;
  uploadSubtext?: string;
  uploadText?: string;
}

const isFileSizeValid = (file: File, maxSize: number) => {
  return file.size <= maxSize;
};

const resizeImageToFile = (file: File, maxWidth: number): Promise<File> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const imageUrl = reader.result as string;
      const image = new (window as any).Image();
      image.src = imageUrl;

      image.onload = () => {
        const scaleFactor = maxWidth / image.width;
        const newHeight = image.height * scaleFactor;

        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (ctx) {
          canvas.width = maxWidth;
          canvas.height = newHeight;
          ctx.drawImage(image, 0, 0, maxWidth, newHeight);

          canvas.toBlob((blob) => {
            if (blob) {
              const resizedFile = new File([blob], file.name, {
                type: 'image/jpeg',
                lastModified: Date.now(),
              });
              resolve(resizedFile);
            } else {
              reject('Failed to create blob from canvas');
            }
          }, 'image/jpeg', 0.7);
        } else {
          reject('Canvas context not available');
        }
      };
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};

const ImageField = ({
  control,
  id,
  isDisabled = false,
  label,
  name,
  uploadSubtext = 'Recommended aspect ratio is 16:9 (700 ⨉ 394 pixels)',
  uploadText = 'Drag & drop or click to browse your files',
}: ImageFieldProps) => {
  const cropperRef = useRef<ReactCropperElement>(null);
  const [tempDimensions, setTempDimensions] = useState({});
  const [tempUrl, setTempUrl] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const maxFileSize = 15 * 1024 * 1024;
  const maxWidth = 1024;

  const handleCloseCrop = () => {
    setIsEditing(false);
  };

  const handleCropChange = () => {
    const cropper = cropperRef?.current?.cropper;
    if (cropper) {
      const data: Cropper.Data = cropper?.getData(true);
      const url: string = cropper?.getCroppedCanvas().toDataURL();
      setTempDimensions(data);
      setTempUrl(url);
    }
  };

  const handleRemove = (field: any) => {
    if (field?.value?.fullDataUrl) {
      URL.revokeObjectURL(field?.value?.fullDataUrl);
    }
    if (field?.value?.cropDataUrl) {
      URL.revokeObjectURL(field?.value?.cropDataUrl);
    }

    field.onChange({
      deleteImage: true,
    });
  };

  const handleSave = (field: any) => {
    field.onChange({
      ...field.value,
      cropDataUrl: tempUrl,
      dimensions: tempDimensions,
    });

    handleCloseCrop();
  };

  const handleDrop = (files: File[], field: any) => {
    const file = files[0];
  
    if (!isFileSizeValid(file, maxFileSize)) {
      setError('File is too large. Please upload an image smaller than 15MB.');
      return;
    }
  
    resizeImageToFile(file, maxWidth)
      .then((resizedFile) => {
        const file = resizedFile;
        const fullDataUrl = URL.createObjectURL(file);
        const newFieldValue: ImageFieldValue = {
          file,
          fullDataUrl,
        };
  
        field.onChange(newFieldValue);
        setIsEditing(true);
      })
      .catch((err) => {
        setError('Failed to process image');
        console.error(err);
      });
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {
        const value: ImageFieldValue = field?.value;
        return (
          <FormControl fullWidth>
            <FormLabel htmlFor={id}>{label}</FormLabel>
            {error && <div style={{ color: 'red' }}>{error}</div>} {/* Display error */}
            {value?.cropDataUrl || (value?.cropDataUrl && !value.deleteImage) ? (
              <Box position="relative">
                <img
                  alt="event banner"
                  src={value?.cropDataUrl}
                  style={{
                    aspectRatio: '16 / 9',
                    borderRadius: 6,
                    maxWidth: '100%',
                    objectFit: 'contain',
                    width: '100%',
                  }}
                />
                {!isDisabled && (
                  <Stack
                    bottom={16}
                    direction="row"
                    gap={1}
                    justifyContent="flex-end"
                    position="absolute"
                    right={12}
                  >
                    <ImageFieldButton
                      data-testid="edit-image-btn"
                      disabled={isDisabled}
                      onClick={() => setIsEditing(true)}
                      startIcon={<NotePencil />}
                    >
                      Edit
                    </ImageFieldButton>
                    <ImageFieldButton
                      data-testid="remove-img-btn"
                      disabled={isDisabled}
                      onClick={() => handleRemove(field)}
                      startIcon={<Trash />}
                    >
                      Delete
                    </ImageFieldButton>
                  </Stack>
                )}
              </Box>
            ) : (
              <FileUploader
                customSubtext={uploadSubtext}
                customText={uploadText}
                Icon={Image}
                id={id}
                isDisabled={isDisabled}
                noun="image"
                onDrop={(files: File[]) => handleDrop(files, field)}
                sx={{ aspectRatio: '16 / 9' }}
              />
            )}
            <Dialog
              aria-labelledby="crop-image-dialog-title"
              fullWidth
              maxWidth="md"
              onClose={handleCloseCrop}
              open={isEditing}
            >
              <DialogTitle id="crop-image-dialog-title">Edit image</DialogTitle>
              <DialogContent>
                <DialogContentText>Crop/resize image</DialogContentText>
                <Box bgcolor="grey.700" width="100%">
                  <Cropper
                    aspectRatio={16 / 9}
                    autoCropArea={value?.fullDataUrl && !value?.cropDataUrl ? 1 : undefined}
                    background={false}
                    crop={handleCropChange}
                    data={value?.dimensions || undefined}
                    guides={false}
                    minCropBoxHeight={30}
                    ref={cropperRef}
                    src={value?.fullDataUrl || undefined}
                    style={{ maxHeight: '70vh', maxWidth: '100%' }}
                    viewMode={2}
                  />
                </Box>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseCrop}>Cancel</Button>
                <Button onClick={() => handleSave(field)} variant="contained">
                  Save
                </Button>
              </DialogActions>
            </Dialog>
          </FormControl>
        );
      }}
    />
  );
};

export default ImageField;

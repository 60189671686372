import React, { useMemo, useRef, useState, useEffect } from 'react';
import {
  AvatarGroup,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { Circle, DotsThreeVertical } from '@phosphor-icons/react';
import * as geopattern from 'geopattern';
import PropTypes from 'prop-types';

import Avatar from 'components/Core/Avatar';

const truncateText = (text, maxLength) => {
  if (!text) return '';
  if (text.length <= maxLength) return text;
  return text.slice(0, maxLength) + '...';
};

const GridCard = ({
  avatars,
  ChipComponent,
  description,
  handleOpenCard,
  imageUrl,
  isDescriptionless = false,
  isImageless = false,
  item,
  itemType,
  menuItems,
  stats,
  statsPosition = 'top',
  title,
  type,
}) => {
  const cardActionAreaRef = useRef(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const [isHoveringMenu, setIsHoveringMenu] = useState(null);
  const menuIsOpen = Boolean(anchorEl);
  const [menuJustClosed, setMenuJustClosed] = useState(false);
  const [truncatedDescription, setTruncatedDescription] = useState('');
  const [truncatedTitle, setTruncatedTitle] = useState('');
  const theme = useTheme();

  const titleRef = useRef(null);
  const descriptionRef = useRef(null);

  // State for dynamic maxTitleLength based on container width
  const [maxTitleLength, setMaxTitleLength] = useState(15);
  const [maxDescriptionLength, setMaxDescriptionLength] = useState(60);

  // Update maxTitleLength based on the width of the title container
  useEffect(() => {
    const handleResize = () => {
      if (titleRef.current) {
        const width = titleRef.current.offsetWidth;
        setMaxTitleLength((width < 165 ? 12 : width < 190 ? 14 : width < 215 ? 16 : width < 235 ? 18 : width < 255 ? 20 : width < 269 ? 22 : 23) * (itemType === 'collection' ? 1 : 2));
        setMaxDescriptionLength((width < 175 ? 40 : width < 205 ? 45 : width < 215 ? 50 : width < 235 ? 55 : width < 255 ? 60 : width < 269 ? 65 : 65));
      }
    };

    // Set up the resize listener
    handleResize(); // Initial calculation
    window.addEventListener('resize', handleResize);

    // Cleanup the listener
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const truncated = truncateText(description, maxDescriptionLength);
    setTruncatedDescription(truncated);
  }, [description, maxDescriptionLength]);

  useEffect(() => {
    const truncated = truncateText(title, maxTitleLength);
    setTruncatedTitle(truncated);
  }, [title, maxTitleLength]);

  const lastAvatar = useMemo(
    () => (avatars?.length > 0 ? avatars[avatars.length - 1] : null),
    [avatars]
  );

  const handleMenuClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setIsHoveringMenu(false);
  };

  const placeholderImageUrl = useMemo(
    () =>
      geopattern
        .generate(title, {
          baseColor: theme.palette.primary.main,
          color: theme.palette.grey[300],
        })
        .toDataUrl(),
    [title, theme.palette.grey, theme.palette.primary.main]
  );

  const renderStats = (itemType) => (
    <Stack
      alignItems={itemType === 'collection' ? 'flex-start' : 'center'}
      direction={itemType === 'collection' ? 'column' : 'row'}
      gap={itemType === 'collection' ? 0 : 2}
      justifyContent={itemType === 'collection' ? 'center' : 'flex-start'}
      wrap='nowrap'
    >
      {stats.map(
        (
          {
            hidden,
            Icon,
            iconColor = theme.palette.grey[700],
            id,
            StatChip,
            text,
          },
          index
        ) => {
          const isLastItem = index === stats.length - 1;
          return (
            <Stack
              alignItems='center'
              direction='row'
              flexGrow={isLastItem ? 1 : 0}
              justifyContent={isLastItem ? 'flex-end' : null}
              key={`${itemType}-${item.id}_${id || text}`}
              visibility={hidden ? 'hidden' : 'visible'}
            >
              {StatChip || (
                <Stack
                  alignItems='center'
                  direction='row'
                  gap={0.5}
                  sx={{ height: '24px' }}
                >
                  <Icon color={iconColor} size={16} />
                  {text || text === 0 ? (
                    <Typography
                      color={theme.palette.grey[700]}
                      variant='overline'
                    >
                      {text}
                    </Typography>
                  ) : null}
                </Stack>
              )}
            </Stack>
          );
        }
      )}
    </Stack>
  );

  return (
    <Card
      data-testid='TODO:DATA-CARD-97001'
      sx={{
        flex: '1 0 auto',
        maxWidth: '100%',
        position: 'relative',
        minHeight: 400,
      }}
    >
      <CardActionArea
        onClick={(e) => {
          if (!isHoveringMenu) {
            if (menuJustClosed) {
              setMenuJustClosed(false);
            } else {
              handleOpenCard(e);
            }
          }
        }}
        ref={cardActionAreaRef}
        sx={{
          alignItems: 'stretch',
          display: 'flex',
          flex: '1 0 auto',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'space-between',
          pb: 0,
        }}
      >
        {isImageless ? (
          <CardHeader
            sx={isImageless ? { alignItems: 'flex-start', flexGrow: 1 } : {}}
            title={title}
            titleTypographyProps={{ variant: 'h4' }}
          />
        ) : (
          <CardMedia
            alt={`${itemType} cover image'`}
            className={imageUrl ? '' : 'needs-back'}
            component='img'
            height='auto'
            image={imageUrl || null}
            src={
              imageUrl
                ? null
                : placeholderImageUrl
                    .replace(/^url\("/g, '')
                    .replace(/"\)$/g, '')
            }
            sx={{
              aspectRatio: '16 / 9',
              height: 'auto',
              objectFit: 'cover',
            }}
            width='100%'
          />
        )}
        <CardContent sx={{ flexGrow: isImageless ? 0 : 1.5, px: 1.5, py: 1 }}>
          <Stack
            alignItems='stretch'
            direction='column'
            gap={1}
            height='100%'
            wrap='nowrap'
          >
            {statsPosition === 'top' && renderStats()}
            {isImageless ? null : (
              <Tooltip
                data-testid='TODO:DATA-TOOLTIP-83064'
                placement='top'
                title={title}
              >
                <Typography ref={titleRef} variant='h4'>{truncatedTitle}</Typography>
              </Tooltip>
            )}
            {statsPosition === 'bottom' && renderStats(itemType)}
            <Typography
              ref={descriptionRef}
              color={theme.palette.grey[700]}
              component='div'
              flexGrow={1}
              fontSize={14}
              variant='body1'
            >
              {truncatedDescription}
            </Typography>
            {type && type?.label?.length > 0 ? (
              <Stack
                alignItems='center'
                direction='row'
                gap={0.5}
                justifyContent='flex-start'
                wrap='nowrap'
              >
                <Circle color={type?.color} size={16} weight='fill' />
                <Typography
                  color='grey.700'
                  flexGrow={1}
                  overflow='hidden'
                  textOverflow='ellipsis'
                  variant='overline'
                  whiteSpace='nowrap'
                >
                  {type.label}
                </Typography>
              </Stack>
            ) : null}
          </Stack>
          <Stack direction='row' width='80%'>
            {ChipComponent && (
              <ChipComponent
                color='primary'
                label={`${item?.events?.length} upcoming events`}
                size='small'
                variant='light'
              />
            )}
          </Stack>
        </CardContent>
        <CardActions sx={{ px: 1.5, py: 0 }}>
          <Grid
            alignItems='center'
            container
            direction='row'
            flexGrow={0}
            justifyContent='space-between'
            minHeight={40}
            spacing={0.5}
            wrap='nowrap'
          >
            <Grid
              alignItems='center'
              container
              direction='row'
              item
              justifyContent='flex-start'
              spacing={0.5}
              wrap='nowrap'
              xs
            >
              <Grid item>
                <AvatarGroup
                  sx={{
                    '& .MuiAvatar-root': {
                      fontSize: 10,
                      height: 20,
                      width: 20,
                    },
                  }}
                >
                  {avatars
                    ? avatars.map((a) => {
                        const avatarId = +a.employee_id || +a?.id;
                        return (
                          <Avatar
                            data-testid='TODO:DATA-AVATAR-53148'
                            employeeId={avatarId}
                            height={20}
                            key={`${itemType}-${item.id}_avatar-emp-${avatarId}`}
                            width={20}
                          >
                            {a?.initials}
                          </Avatar>
                        );
                      })
                    : '&nbsp;'}
                </AvatarGroup>
              </Grid>
              {lastAvatar && (
                <Grid
                  item
                  sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                >
                  <Typography
                    color={theme.palette.grey[900]}
                    display='inline'
                    fontSize={14}
                    sx={{ maxWidth: '100%' }}
                    variant='overline'
                    whiteSpace='nowrap'
                  >
                    {lastAvatar.full_name}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </CardActions>
      </CardActionArea>
      {menuItems?.length > 0 && (
        <>
          <IconButton
            aria-controls={menuIsOpen ? 'card-actions-menu' : undefined}
            aria-expanded={menuIsOpen ? 'true' : undefined}
            aria-haspopup='true'
            id='card-actions-menu-button'
            onClick={handleMenuClick}
            // INFO: These styles are necessary for the menu button to appear on the card, but not trigger the card click action when clicked
            sx={{ bottom: 0, position: 'absolute', right: 0, zIndex: 99 }}
          >
            <DotsThreeVertical color={theme.palette.grey[900]} weight='bold' />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id='card-actions-menu'
            MenuListProps={{
              'aria-labelledby': 'card-actions-menu-button',
            }}
            onClose={handleMenuClose}
            onMouseEnter={() => setIsHoveringMenu(true)}
            onMouseLeave={() => {
              setIsHoveringMenu(false);
              setAnchorEl(null);
            }}
            open={menuIsOpen}
          >
            {menuItems.map(({ handleClick, icon, label }) => (
              <MenuItem
                key={`${itemType}-${item.id}_menuItem-${label}`}
                onClick={() => {
                  handleClick();
                  handleMenuClose();
                }}
              >
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText>{label}</ListItemText>
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
    </Card>
  );
};

export default GridCard;

GridCard.propTypes = {
  avatars: PropTypes.array,
  canEdit: PropTypes.bool,
  ChipComponent: PropTypes.elementType,
  description: PropTypes.string,
  handleOpenCard: PropTypes.func.isRequired,
  imageUrl: PropTypes.string,
  isDescriptionless: PropTypes.bool,
  isImageless: PropTypes.bool,
  item: PropTypes.any,
  itemType: PropTypes.string,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      handleClick: PropTypes.func,
      Icon: PropTypes.node,
      label: PropTypes.string,
    })
  ),
  stats: PropTypes.arrayOf(
    PropTypes.shape({
      hidden: PropTypes.bool,
      Icon: PropTypes.any,
      id: PropTypes.string,
      StatChip: PropTypes.any,
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  statsPosition: PropTypes.oneOf(['top', 'bottom']),
  title: PropTypes.string.isRequired,
  type: PropTypes.shape({
    color: PropTypes.string,
    label: PropTypes.string,
  }),
};

import * as Sentry from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import {
  CommunicationMethodId,
  InvitationFormData,
} from 'components/Events/Controls/Communications/communication.types';
import FtnEvent from 'types/FtnEvent';
import { reactQueryAxios } from 'utils/axios';
import { handleSlackEmojiConversion } from 'utils/text';

export type EventInvitationSaveParams = {
  commId: number | null;
  eventId: number;
  method: CommunicationMethodId;
  pauseActive: boolean;
  pauseAllowed: boolean;
};

const eventInvitationSaveData = (
  invitationDetails: InvitationFormData,
  eventId: number,
  commId: number | null,
  method: CommunicationMethodId,
  rsvp: { pauseActive: boolean; pauseAllowed: boolean }
) => ({
  communication_history_id: invitationDetails?.communicationHistoryId || null,
  event_id: eventId,
  event_invitation_id: commId,
  invite_type: method,
  is_scheduled: invitationDetails?.is_scheduled || false,
  notifies: invitationDetails?.notifies || false,
  recipient_attributes: {
    0: {
      recipient_ids: invitationDetails?.selections?.contact?.map((s) => s.id),
      recipient_type: 'Invitees',
    },

    1: {
      recipient_ids: invitationDetails?.selections?.group?.map((s) => s.id),
      recipient_type: 'Groups',
    },

    2: {
      recipient_ids: invitationDetails?.selections?.list?.map((s) => s.id),
      recipient_type: 'Lists',
    },

    3: {
      recipient_ids: invitationDetails?.selections?.office?.map((s) => s.id),
      recipient_type: 'Offices',
    },

    4: {
      recipient_ids: invitationDetails?.selections?.channel?.map((s) => s.id),
      recipient_type: 'SlackChannels',
    },
  },
  rsvp_pause_active: rsvp.pauseActive,
  rsvp_pause_allowed: rsvp.pauseAllowed,
  scheduled_for: invitationDetails?.scheduled_for || null,
  ...(method === 'calendar'
    ? {
        calendars: invitationDetails?.calendars?.map((c) => ({
          ...(c.is_deleted ? { _destroy: 1 } : {}),
          calendar_id: c.calendar_id || c.id,
          is_personal:
            c.name?.toLowerCase().includes('personal') || c.is_personal,
        })),
      }
    : {}),
  ...(method === 'email'
    ? {
        invite_body: invitationDetails?.invite_body,
        invite_subject: invitationDetails?.invite_subject,
      }
    : {}),
  ...(method === 'slack'
    ? {
        has_calendar_invite: invitationDetails?.has_calendar_invite,
        invite_body: handleSlackEmojiConversion(invitationDetails?.invite_body),
      }
    : {}),
  // ...(commId ? { comm_history_id: commId } : {}),
});

const apiSave = async ({
  apiClient,
  commId,
  eventId,
  inviteDetails,
  method,
  pauseActive,
  pauseAllowed,
}: EventInvitationSaveParams & {
  apiClient: any;
  inviteDetails: InvitationFormData;
}) => {
  const url = commId
    ? `/api/event_invitations/reschedule?event_invitation_id=${commId}&communication_history_id=${inviteDetails.communicationHistoryId}`
    : '/api/event_invitations';

  const d = eventInvitationSaveData(inviteDetails, eventId, commId, method, {
    pauseActive,
    pauseAllowed,
  });

  return apiClient({
    data: d,
    method: 'post',
    noTimeout: true,
    url,
  });
};

export const useEventInvitationSave = ({
  commId,
  dataHandler = () => undefined,
  eventId,
  method,
  pauseActive,
  pauseAllowed,
}: EventInvitationSaveParams & {
  dataHandler?: (e: FtnEvent) => unknown;
}) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation<InvitationFormData, Error, InvitationFormData>({
    mutationFn: (inviteDetails) =>
      apiSave({
        apiClient: reactQueryAxios,
        commId,
        eventId,
        inviteDetails,
        method,
        pauseActive,
        pauseAllowed,
      }),
    onError: (error: Error) => {
      console.error(error);
      enqueueSnackbar('There was an error saving your event', {
        variant: 'error',
      });
      Sentry.captureException(error);
    },
    onSuccess: (ftnEvent: any) => {
      queryClient.setQueryData(['event', ftnEvent.id], ftnEvent);
      dataHandler(ftnEvent);
    },
  });
};

import * as Sentry from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { useSnackbar } from 'notistack';

import { Expense } from 'types/EventBudget';
import { reactQueryAxios } from 'utils/axios';

interface UpdateExpenseItemParams {
  apiClient: any;
  data: Expense;
  expenseItemId: number;
}

const updateExpenseItem = async ({
  apiClient,
  data,
  expenseItemId,
}: UpdateExpenseItemParams) =>
  apiClient({
    data,
    method: 'put',
    url: `/api/expense_items/${expenseItemId}`,
  });

export const useExpenseUpdate = ({
  dataHandler = () => undefined,
  eventId,
  showToast = true,
}: {
  dataHandler?: () => void;
  eventId: number;
  showToast?: boolean;
}) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: (data: Expense) =>
      updateExpenseItem({
        apiClient: reactQueryAxios,
        data,
        expenseItemId: data.id,
      }),

    onError: (error: Error) => {
      enqueueSnackbar('Something went wrong! Please try again', {
        variant: 'error',
      });
      Sentry.captureException(error);
    },

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['event', `${eventId}`, 'budget'],
      });
      dataHandler();
    },
  });
};

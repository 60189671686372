import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import {
  AddCircleOutline,
  EventAvailable,
  StickyNote2,
} from '@mui/icons-material';
import {
  Alert,
  AlertTitle,
  Box,
  Card,
  CardContent,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  Typography,
} from '@mui/material';

import AnnouncementRecipientsField from 'components/Collections/AnnouncementRecipientsField';
import AnnouncementSlackPreview from 'components/Collections/AnnouncementSlackPreview';
import CommunicationMessageField from 'components/Events/Controls/Communications/CommunicationMessageField';

interface Props {
  collection: any;
  form: UseFormReturn<any>;
}

const CollectionAnnouncementFieldsAndPreview: React.FC<Props> = ({
  collection,
  form,
}) => {
  const { register, watch } = form;
  const messageBody = watch('message_body');

  return (
    <Grid container spacing={4}>
      <Grid item md={6} xs={12}>
        <Card sx={{ padding: 2 }}>
          <CardContent>
            <Stack spacing={4}>
              <Stack spacing={1}>
                <Box alignItems='center' display='flex'>
                  <AddCircleOutline sx={{ marginRight: 1 }} />
                  <Typography variant='h4'>Recipients</Typography>
                </Box>
                <Box mt={1}>
                  <AnnouncementRecipientsField
                    collectionId={collection.id}
                    hookForm={form}
                  />
                </Box>
                <Typography color='textSecondary' variant='body2'>
                  Members of selected Slack channels will be added to the guest
                  list for all events in this collection, unless already
                  invited.
                </Typography>
              </Stack>
              <Stack spacing={1}>
                <Box alignItems='center' display='flex'>
                  <EventAvailable sx={{ marginRight: 1 }} />
                  <Typography variant='h4'>Calendar invitation</Typography>
                </Box>
                <FormControlLabel
                  control={<Switch {...register('send_calendar_invites')} />}
                  label={
                    <Box>
                      <Typography component='span' variant='body1'>
                        Send recipients calendar invitations
                      </Typography>
                      <Typography
                        color='textSecondary'
                        sx={{ display: 'block', mt: 0.5 }}
                        variant='body2'
                      >
                        Add calendar invitations for all Slack channel members.
                      </Typography>
                    </Box>
                  }
                  sx={{
                    '.MuiFormControlLabel-label': { marginLeft: 0 },
                    alignItems: 'flex-start',
                    marginBottom: '0',
                    marginTop: '0',
                  }}
                />
              </Stack>
              <Stack spacing={1}>
                <Box alignItems='center' display='flex'>
                  <StickyNote2 sx={{ marginRight: 1 }} />
                  <Typography variant='h4'>Message</Typography>
                </Box>
                <Box mt={1}>
                  <CommunicationMessageField
                    hideLabel
                    hookForm={form}
                    isSlack
                  />
                </Box>
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      </Grid>

      <Grid display='flex' flexDirection='column' gap={2} item md={6} xs={12}>
        <Alert severity='info'>
          <AlertTitle>This is a preview of your Slack message</AlertTitle>
        </Alert>
        <Card>
          <CardContent>
            <AnnouncementSlackPreview
              collection={collection}
              customMessage={messageBody}
              customPreviewComponent
              slackAppName='Collection App'
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default CollectionAnnouncementFieldsAndPreview;

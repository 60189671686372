import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { UseFormReturn } from 'react-hook-form';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Divider,
  Grid,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { CheckCircle } from '@phosphor-icons/react';
import { useFlag } from '@unleash/proxy-client-react';

import { CheckListItem } from 'components/Core/CheckListItem';
import {
  CommunicationFormData,
  CommunicationMethodId,
} from 'components/Events/Controls/Communications/communication.types';
import ContactsOrChannelsTab from 'components/Events/Controls/Invites/ContactsOrChannelsTab';
import InvitationSelectCalendars from 'components/Events/Controls/Invites/InvitationSelectCalendars';
import InvitationSelectCollections from 'components/Events/Controls/Invites/InvitationSelectCollections';
import InvitationSelectContactsTable from 'components/Events/Controls/Invites/InvitationSelectContactsTable';
import InviteMessage from 'components/Events/Controls/Invites/InviteMessage';
import SelectedGuests from 'components/Events/Controls/Invites/SelectedGuests';
import { ManagerContext } from 'components/Events/Manager/ManagerContext';
import ButtonTabs from 'components/shared/ButtonTabs';
import CsvUploader from 'components/shared/CsvUploader';
import { eventInvitationGuestTypes } from 'constants/event.constants';
import { AuthContext, AuthContextType } from 'contexts/AuthContext';
import { ListsContext } from 'contexts/ListsContext';
import { SlackChannelsContext } from 'contexts/SlackChannelsContext';
import {
  EventInvitationGuestCollectionType,
  EventInvitationGuestType,
} from 'types/EventInvitationGuestType';
import { flexColumn } from 'utils/styles';

const InvitationSelectGuests = ({
  commId,
  form,
  method,
  savedEvent,
}: {
  commId: number;
  form: UseFormReturn<CommunicationFormData>;
  method: CommunicationMethodId;
  savedEvent: any;
}) => {
  const [lists] = useContext(ListsContext);
  const { channels } = useContext(SlackChannelsContext);
  const {
    userProvider: { user },
  } = useContext(AuthContext) as AuthContextType;
  const {
    actions: { applySingle, setActiveInvitationMethod: setActiveMethod },
    state: { activeInvitationMethod: activeMethod, inManagerContext, invites },
  } = useContext(ManagerContext);

  const orgAllowsSlackGuestCounts = useFlag('event-invites-slack-guest-counts');

  const theme = useTheme();

  const hasGoogleAuth = useMemo(() => user?.has_google_calendar_auth, [user]);
  const isSlack = useMemo(() => method === 'slack', [method]);

  const guestTypes = useMemo(
    () =>
      eventInvitationGuestTypes.filter((type: EventInvitationGuestType) =>
        isSlack
          ? type.isSlackOnly
          : type.id === 'list'
          ? lists && lists?.length > 0
          : true
      ),
    [isSlack, lists]
  );

  const [activeGuestTypeTab, setActiveGuestTypeTab] = useState(guestTypes[0]);

  const { getValues, watch } = form;

  const inviteBody = watch('invite_body');
  const inviteSubject = watch('invite_subject');

  React.useEffect(() => {
    const subscription = watch((_value, { name }) => {
      if (name?.match(/selection/g)) {
        const newSelections = getValues('selections');
        const sectionsWithSelections = newSelections
          ? Object.values(newSelections).filter((s) => s?.length > 0)
          : [];
        const selectedContactIds =
          newSelections?.contact && newSelections?.contact?.length > 0
            ? newSelections?.contact?.map((c) => c.id)
            : [];
        const selectedMemberIds =
          sectionsWithSelections.flatMap((s) =>
            s.flatMap((t) => t.members?.flatMap((m: any) => m.id))
          ) || [];
        const allIndividualIds = [
          ...selectedContactIds,
          ...selectedMemberIds,
        ].filter((i) => i !== undefined);
        const uniqueMembers = new Set(allIndividualIds);
        form.setValue('uniqueGuestCount', uniqueMembers.size);
        form.setValue('selectedGuestIds', uniqueMembers);
      }
    });
    return () => subscription.unsubscribe();
  }, [form, getValues, watch]);

  const hasMessage = useMemo(
    () => method !== 'calendar' && (inviteBody || '').length > 0,
    [inviteBody, method]
  );

  useEffect(() => {
    if (!user?.has_google_calendar_auth) {
      form.setValue('has_calendar_invite', false);
    }
  }, [form, user?.has_google_calendar_auth]);

  const handleChannelSelections = useCallback(
    (selectedIds: number[]) => {
      const selected =
        channels?.filter((channel: { id: number }) =>
          selectedIds?.includes(channel?.id)
        ) || null;
      if (selected?.length > 0) {
        if (activeMethod !== method) {
          setActiveMethod(method);
        }
      } else {
        setActiveMethod(null);
      }
      applySingle(selected, `invites.slack.channels`);
    },
    [activeMethod, applySingle, method, setActiveMethod, channels]
  );

  const guestSelector = useMemo(
    () => (
      <>
        <Typography flex={0} variant='h4'>
          Select guests
        </Typography>
        {isSlack ? null : (
          <ButtonTabs
            onChange={(index: number) =>
              guestTypes[index] && setActiveGuestTypeTab(guestTypes[index])
            }
            tabs={guestTypes
              .filter((type) =>
                method === 'slack' ? type.isSlackOnly : !type.isSlackOnly
              )
              .map(({ id, label }) => ({ key: id, label }))}
            type='guest-types'
            value={guestTypes.indexOf(activeGuestTypeTab)}
            withCount={false}
            withSeparators
          />
        )}
        {guestTypes
          .filter((type) =>
            method === 'slack' ? true : activeGuestTypeTab.id === type.id
          )
          .map((type) => {
            if (type.id === 'contact') {
              return (
                <Box
                  alignItems='stretch'
                  display={activeGuestTypeTab?.id === type.id ? 'flex' : 'none'}
                  key='contacts'
                >
                  <InvitationSelectContactsTable />
                </Box>
              );
            } else {
              return (
                <Box
                  alignItems='stretch'
                  display={activeGuestTypeTab?.id === type.id ? 'flex' : 'none'}
                  flexDirection='column'
                  key={type.id}
                  width='100%'
                >
                  <InvitationSelectCollections
                    commId={commId}
                    guestType={type as EventInvitationGuestCollectionType}
                  />
                </Box>
              );
            }
          })}
      </>
    ),
    [isSlack, guestTypes, activeGuestTypeTab, method, commId]
  );

  useEffect(() => {
    if (!user?.has_google_calendar_auth) {
      applySingle(false, 'invites.slack.has_calendar_invite');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.has_google_calendar_auth]);

  const hasCalendarInvite = watch('has_calendar_invite');

  const slackSelectors = useMemo(
    () => (
      <Box
        alignItems='stretch'
        display='flex'
        flex={1}
        flexDirection='row'
        gap={2}
        maxHeight='calc(100% - 120px)'
      >
        <Box
          display='flex'
          flex={1}
          flexDirection='column'
          gap={1}
          maxWidth='100%'
        >
          <CheckListItem
            disabled={!hasGoogleAuth}
            hideTooltip={hasGoogleAuth}
            onClick={() =>
              form.setValue('has_calendar_invite', !hasCalendarInvite)
            }
            option={{
              id: 'has_calendar_invite',
              name: 'Add a calendar invitation for all invited Slack channel members',
            }}
            sx={{ mt: -1.5 }}
            tooltipText='Please go to the profile page and integrate with Google Calendar to use this feature'
            value={Boolean(hasCalendarInvite)}
          />
          <Typography mt={1} variant='h4'>
            Select channels
          </Typography>
          {orgAllowsSlackGuestCounts && isSlack ? (
            <InvitationSelectCollections
              commId={commId}
              guestType={
                activeGuestTypeTab as EventInvitationGuestCollectionType
              }
            />
          ) : (
            <ContactsOrChannelsTab
              fixedValues={[]}
              inviteMethod={method}
              isSelectable
              isSlackChannels
              nounPlural='channels'
              onChange={handleChannelSelections}
              options={channels}
              searchOnly
              showInvitedAsFixed={inManagerContext}
              showStatusChip={inManagerContext}
            />
          )}
        </Box>
      </Box>
    ),
    [
      hasGoogleAuth,
      hasCalendarInvite,
      orgAllowsSlackGuestCounts,
      isSlack,
      commId,
      activeGuestTypeTab,
      method,
      handleChannelSelections,
      channels,
      inManagerContext,
      form,
    ]
  );

  return (
    <Grid
      alignItems='flex-start'
      container
      direction='row'
      position='sticky'
      spacing={3}
    >
      <Grid display='flex' flexDirection='column' gap={2} item md={8} xs={12}>
        <Card
          data-testid='select-invitees'
          sx={{ flex: 1, overflow: 'visible' }}
        >
          <CardContent
            sx={{
              gap: 1,
              height: '100%',
              ...flexColumn,
            }}
          >
            <Stack direction='row' justifyContent='space-between'>
              <Typography variant='h4'>
                {method === 'calendar'
                  ? 'Select calendars'
                  : 'Personalize invitation'}
              </Typography>
              {hasMessage ? (
                <Chip
                  color='success'
                  icon={
                    <CheckCircle color={theme.palette.success.dark} size={15} />
                  }
                  label='Message added'
                  size='small'
                  variant='light'
                />
              ) : null}
            </Stack>
            {method === 'calendar' && (
              <InvitationSelectCalendars form={form} savedEvent={savedEvent} />
            )}
            {method === 'email' || isSlack ? (
              <InviteMessage
                defaultBody={inviteBody}
                defaultSubject={
                  inviteSubject ||
                  (method === 'email'
                    ? `You are invited to ${savedEvent?.name}`
                    : '')
                }
                disable={savedEvent?.rsvp_pause_active}
                hasMessage={hasMessage}
                method={method}
                onSubmit={(body: string, subject: string | null) => {
                  form.setValue('invite_body', body, { shouldDirty: true });
                  if (subject != null) {
                    form.setValue('invite_subject', subject, {
                      shouldDirty: true,
                    });
                  }
                }}
              />
            ) : null}
            {isSlack ? <>{slackSelectors}</> : <>{guestSelector}</>}
          </CardContent>
        </Card>
      </Grid>
      <Grid
        item
        md={4}
        sx={{
          position: 'sticky',
          top: -8,
          xs: 12,
        }}
      >
        <Card
          data-testid='selected-guests'
          sx={{ position: 'sticky', top: -8, xs: 12 }}
        >
          <SelectedGuests
            isSelectable
            nounPlural={isSlack ? 'channels' : 'guests'}
          />
          {!isSlack && (
            <>
              <Divider />
              <CardActions sx={{ py: 3 }}>
                <CsvUploader
                  onUpload={(contacts, list) => {
                    // Auto select imported contacts
                    applySingle(
                      [...invites[method].invitees, ...contacts],
                      `invites[${method}].invitees`
                    );
                    // Auto select imported list if it exists
                    if (list?.id) {
                      applySingle(
                        [...invites[method].lists, list],
                        `invites[${method}].lists`
                      );
                    }
                  }}
                  UploadButton={(props) => (
                    <Button
                      {...props}
                      data-testid='upload-contacts'
                      fullWidth
                      variant='bordered'
                    >
                      Upload contacts
                    </Button>
                  )}
                />
              </CardActions>
            </>
          )}
        </Card>
      </Grid>
    </Grid>
  );
};

export default InvitationSelectGuests;

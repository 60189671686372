import React, { useContext } from 'react';
import { Link } from '@mui/material';
import PropTypes from 'prop-types';

import EventCard from 'components/Home/EventCard';
import GridCards from 'components/shared/GridCards';
import { AuthContext } from 'contexts/AuthContext';
import { axiosAuthenticated as axios } from 'utils/axios';

const EventCards = ({
  chunk,
  collection,
  inProfile,
  removeFromAllChunks,
  tab,
}) => {
  const {
    userProvider: { user },
  } = useContext(AuthContext);

  return (
    <GridCards
      CardComponent={(ev) => (
        <EventCard
          axios={axios}
          collection={collection}
          event={ev}
          key={ev.id}
          removeFromAllChunks={removeFromAllChunks}
          tab={tab}
          user={user}
        />
      )}
      chunk={chunk}
      isNarrow={inProfile}
      NoDataComponent={
        (user?.is_admin || user?.is_organizer) && (
          <Link data-testid='create-new-event' href='/event/new/create'>
            Create new event
          </Link>
        )
      }
      noun='events'
    />
  );
};

EventCards.propTypes = {
  chunk: PropTypes.shape({
    hasMore: PropTypes.bool,
    isLoading: PropTypes.bool,
    requestMore: PropTypes.func,
    values: PropTypes.array,
  }),
  collection: PropTypes.any,
  inProfile: PropTypes.bool,
  removeFromAllChunks: PropTypes.any,
  tab: PropTypes.any,
};

export default EventCards;

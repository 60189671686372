import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Cloudinary } from '@cloudinary/url-gen';
import { useTheme } from '@mui/material';
import {
  CalendarBlank,
  Copy,
  Eye,
  GlobeHemisphereWest,
  MinusCircle,
  PencilSimple,
  User,
} from '@phosphor-icons/react';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import DeleteModal from 'components/Core/DeleteModal';
import { useCloneEvent } from 'components/Events/Hooks/Actions';
import GridCard from 'components/shared/GridCard';
import { AuthContext } from 'contexts/AuthContext';
import { OrganizationContext } from 'contexts/OrganizationContext';

const EventCard = ({ axios, collection, event, removeFromAllChunks }) => {
  const queryClient = useQueryClient();
  const {
    userProvider: { isOrganizerOrAdmin, user },
  } = useContext(AuthContext);
  const [organization, , , , , eventTypeColors] =
    useContext(OrganizationContext);

  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const [, setAddBlur] = useState(false);
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
  const [isWorking, setIsWorking] = useState(false);

  const hosts = event?.event_hosts_employees || event?.hosts;
  const today = dayjs();
  const canEdit =
    (user.is_admin || event.host_ids?.includes(user.employee_id)) &&
    (!event.published || today.isBefore(event.start));

  const cld = useMemo(
    () =>
      new Cloudinary({
        cloud: {
          cloudName: 'five-to-nine',
          secure: process.env.NODE_ENV !== 'development',
        },
      }),
    []
  );

  const coverImageUrl = useMemo(() => {
    if (event?.cover_image) {
      if (typeof event?.cover_image === 'string') {
        return event?.cover_image;
      } else if (
        typeof event?.cover_image === 'object' &&
        typeof event?.cover_image?.key === 'string' &&
        event?.cover_image?.key?.length > 0
      ) {
        return cld.image(event?.cover_image?.key)?.toURL();
      }
    } else if (event?.coverImageUrl) {
      return event?.coverImageUrl;
    } else {
      return null;
    }
  }, [cld, event?.cover_image, event?.coverImageUrl]);

  const startTask = () => {
    setIsWorking(true);
    setAddBlur(true);
  };

  const endTask = () => {
    setIsWorking(false);
    setAddBlur(false);
  };

  const showErrorSnackbar = () => {
    enqueueSnackbar('Something went wrong, please try again.', {
      variant: 'error',
    });
  };

  const cloneEvent = useCloneEvent(
    startTask,
    endTask,
    (resp) => {
      enqueueSnackbar(
        `The event was duplicated. You can update it as needed then publish it.`,
        { variant: 'success' }
      );
      navigate(`/event/${resp.id}/create`);
    },
    () => showErrorSnackbar()
  );

  const deleteEvent = (id) => {
    if (!id) {
      showErrorSnackbar();
    } else if (id && !isWorking) {
      startTask();
      axios(
        {
          method: 'delete',
          url: `/api/events/${id}`,
        },
        (res) => {
          if (res.data && res.data.error) {
            showErrorSnackbar();
            endTask();
          } else {
            removeFromAllChunks(id);
            enqueueSnackbar(`Your event was deleted.`, {
              variant: 'success',
            });
            endTask();
            if (collection) {
              setTimeout(() => {
                queryClient.invalidateQueries({
                  queryKey: ['fetchEventsCollection', collection.id],
                });
              }, 1000);
            }
          }
        },
        () => {
          showErrorSnackbar();
          endTask();
        }
      );
    }
  };

  const viewEvent = useCallback(
    () =>
      navigate(`/event/${event.id}/view`, {
        from: location?.pathname,
      }),
    [event.id, location?.pathname, navigate]
  );

  const openEvent = useCallback(() => {
    const isGroupEvent = location.pathname.includes('group') && event?.id;
    const isPublished = isGroupEvent || event.published;
    if (isOrganizerOrAdmin) {
      const page = 'event';
      const slug = isPublished ? 'details' : 'create';
      navigate(`/${page}/${event.id}/${slug}`);
    } else {
      viewEvent();
    }
  }, [
    event.id,
    event.published,
    isOrganizerOrAdmin,
    location.pathname,
    navigate,
    viewEvent,
  ]);

  const menuItemIconProps = useMemo(
    () => ({
      color: theme.palette.grey[900],
    }),
    [theme]
  );

  const menuItems = useMemo(
    () => [
      ...(canEdit
        ? [
            {
              handleClick: openEvent,
              icon: <PencilSimple {...menuItemIconProps} />,
              label: 'Edit',
            },
          ]
        : []),
      {
        handleClick: viewEvent,
        icon: <Eye {...menuItemIconProps} />,
        label: 'View',
      },
      {
        handleClick: () => cloneEvent(event.id),
        icon: <Copy {...menuItemIconProps} />,
        label: 'Duplicate',
      },
      ...(isOrganizerOrAdmin
        ? [
            {
              handleClick: () => setIsConfirmingDelete(true),
              icon: <MinusCircle {...menuItemIconProps} />,
              label: 'Delete',
            },
          ]
        : []),
    ],
    [
      canEdit,
      openEvent,
      menuItemIconProps,
      viewEvent,
      isOrganizerOrAdmin,
      cloneEvent,
      event.id,
    ]
  );

  return (
    <>
      <GridCard
        avatars={hosts}
        canEdit={canEdit}
        description={event?.safe_description || event?.safeDescription}
        handleOpenCard={openEvent}
        imageUrl={coverImageUrl || null}
        item={event}
        itemType='event'
        menuItems={menuItems}
        stats={[
          {
            Icon: User,
            id: 'attendees',
            text: event.invitees_count || event.inviteesCount || 0,
          },
          {
            Icon: CalendarBlank,
            id: 'date',
            text: dayjs(event.start).format('MM/DD/YYYY'),
          },
          {
            hidden: event.privacy === 'invite_only',
            Icon: GlobeHemisphereWest,
            id: 'public',
          },
        ]}
        title={event.name}
        type={{
          color:
            eventTypeColors !== null
              ? // eslint-disable-next-line react/prop-types
                eventTypeColors[event?.event_type_id]
              : null,
          label: organization?.event_types?.find(
            // eslint-disable-next-line react/prop-types
            (t) => t?.id === event?.event_type_id
          )?.name,
        }}
      />
      <DeleteModal
        noun='event'
        onCancel={() => setIsConfirmingDelete(false)}
        onDelete={() => {
          deleteEvent(Number(event.id));
          setIsConfirmingDelete(false);
        }}
        owner={organization?.display_name}
        show={isConfirmingDelete}
        subject={event?.name}
      />
    </>
  );
};

EventCard.propTypes = {
  axios: PropTypes.func,
  collection: PropTypes.any,
  event: PropTypes.shape({
    cover_image: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        key: PropTypes.any,
      }),
    ]),
    coverImageUrl: PropTypes.string,
    event_hosts_employees: PropTypes.array,
    event_type_ids: PropTypes.arrayOf(PropTypes.number),
    host_ids: PropTypes.array,
    hosts: PropTypes.array,
    id: PropTypes.any,
    invitees_count: PropTypes.number,
    inviteesCount: PropTypes.number,
    name: PropTypes.string,
    privacy: PropTypes.string,
    published: PropTypes.bool,
    safe_description: PropTypes.any,
    safeDescription: PropTypes.any,
    start: PropTypes.any,
  }),
  removeFromAllChunks: PropTypes.func,
  tab: PropTypes.any,
  user: PropTypes.shape({
    employee_id: PropTypes.any,
    is_admin: PropTypes.bool,
  }),
};

export default EventCard;

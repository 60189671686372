import React, { useContext, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  Button,
  capitalize,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { ArrowsInSimple, ArrowsOutSimple } from '@phosphor-icons/react';

import { communicationMethodDetails } from 'components/Events/Controls/Communications/communication.constants';
import {
  CommunicationFormData,
  CommunicationMethodId,
  CommunicationTypeId,
} from 'components/Events/Controls/Communications/communication.types';
import PreviewEmailInvitation from 'components/Events/Controls/Communications/PreviewEmailInvitation';
import PreviewEmailMessage from 'components/Events/Controls/Communications/PreviewEmailMessage';
import PreviewSlackMessage from 'components/Events/Controls/Communications/PreviewSlackMessage';
import { ManagerContext } from 'components/Events/Manager/ManagerContext';

type Props = {
  method: CommunicationMethodId;
  type: CommunicationTypeId;
};

const PreviewInvitation = ({ method, type = 'invitation' }: Props) => {
  const {
    state: { event, invite_message: inviteMessage, savedEvent },
  } = useContext(ManagerContext);
  const form = useFormContext<CommunicationFormData>();

  const [isExpanded, setIsExpanded] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
    setIsExpanded(false);
  };

  const handleExpandToggle = () => setIsExpanded(!isExpanded);

  const customMessage = useMemo(
    () =>
      type === 'invitation'
        ? form.getValues('invite_body')
        : inviteMessage?.[method]?.invite_body,
    [inviteMessage, method]
  );

  const methodProps = useMemo(
    () => ({
      customMessage,
      event: event || savedEvent,
    }),
    [customMessage, event, savedEvent]
  );

  const methodDetails = communicationMethodDetails[method];

  return method !== 'calendar' ? (
    <>
      <Button onClick={() => setIsOpen(!isOpen)}>Preview</Button>
      <Dialog
        fullWidth
        maxWidth={isExpanded ? 'xl' : 'sm'}
        onClose={handleClose}
        open={isOpen}
        PaperProps={{ sx: { transition: 'all 300ms ease-in' } }}
        scroll='paper'
      >
        <DialogTitle
          sx={{ boxShadow: 'rgba(0, 0, 0, 0.1) 0 2px 4px 0', pt: 3, zIndex: 1 }}
        >
          <Stack direction='row' justifyContent='space-between'>
            <Typography paragraph variant='inherit'>
              {capitalize(method || '')} {type} preview
            </Typography>
            {method === 'email' ? (
              <IconButton onClick={handleExpandToggle}>
                {isExpanded ? <ArrowsInSimple /> : <ArrowsOutSimple />}
              </IconButton>
            ) : null}
          </Stack>
          <Typography variant='body1'>
            This is what your {methodDetails?.label} {type} will look like to
            event guests.
          </Typography>
        </DialogTitle>
        <DialogContent
          sx={{
            bgcolor: 'grey.100',
            px: 3,
            py: 2,
          }}
        >
          <Stack alignItems='center' direction='column' mt={2}>
            <Card sx={method === 'email' ? { maxWidth: 600 } : {}}>
              {method === 'slack' ? (
                <PreviewSlackMessage
                  {...methodProps}
                  slackAppName='Five to Nine'
                />
              ) : type === 'invitation' ? (
                <PreviewEmailInvitation {...methodProps} />
              ) : (
                <PreviewEmailMessage {...methodProps} />
              )}
            </Card>
          </Stack>
        </DialogContent>
        <DialogActions
          sx={{ boxShadow: 'rgba(0, 0, 0, 0.1) 0 -2px 4px 0', px: 3, py: 1 }}
        >
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  ) : null;
};

export default PreviewInvitation;

import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Card, CardContent } from '@mui/material';
import {
  Clock,
  Gear,
  MapPin,
  Sparkle,
  UsersThree,
} from '@phosphor-icons/react';
import { useFlag } from '@unleash/proxy-client-react';

import EventBannerImageField from 'components/Event/EventBannerImageField';
import EventDescriptionField from 'components/Event/EventDescriptionField';
import EventFormDateTimeFields from 'components/Event/EventFormDateTimeFields';
import EventGroupsField from 'components/Event/EventGroupsField';
import EventHostedByField from 'components/Event/EventHostedByField';
import EventLocationTypeField from 'components/Event/EventLocationTypeField';
import EventOfficesField from 'components/Event/EventOfficesField';
import EventOrganizerField from 'components/Event/EventOrganizerField';
import EventPrivacyField from 'components/Event/EventPrivacyField';
import EventResourcesField from 'components/Event/EventResourcesField';
import EventRsvpPausingField from 'components/Event/EventRsvpPausingField';
import EventTitleField from 'components/Event/EventTitleField';
import EventTypeField from 'components/Event/EventTypeField';
import FormSection from 'components/shared/FormSection';
import SummaryFieldsWrapper from 'components/shared/SummaryFieldsWrapper';
import { EventDetailsFormData } from 'types/EventDetailsForm';
import { hasStarted, isOver } from 'utils/event';

interface EventFormProps {
  disableFields?: boolean;
  eventId?: number;
  hookForm: UseFormReturn<EventDetailsFormData>;
  isLoading?: boolean;
  isPublished: boolean;
  meetingCreator: {
    creatorId?: number;
    creatorName?: string;
    isSaved: boolean;
  };
}

const EventForm = ({
  disableFields = false,
  eventId,
  hookForm,
  isLoading,
  isPublished,
  meetingCreator: { creatorId, creatorName, isSaved },
}: EventFormProps) => {
  const eventBudgetFlagEnabled = useFlag('event-budget');

  const start = hookForm.watch('start');
  const ends = hookForm.watch('ends');
  const hasStartedV = hasStarted({ ends, start });
  const isOverV = isOver(ends);

  const commonProps = {
    disableFields,
    isLoading,
    ...hookForm,
  };

  return (
    <Card>
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 5,
          px: 6,
          py: 4,
        }}
      >
        <FormSection
          description='Generate guest interest with a catchy title and a captivating image!'
          fields={
            <>
              <EventTitleField {...commonProps} />
              <EventBannerImageField
                {...commonProps}
                hasStarted={isPublished && hasStartedV}
                isOver={isOverV}
              />
              {/*     // INFO: SummaryFieldsWrapper needs to be the *immediate* parent
              of any/all SummaryField // components, which is why it can't live
              outside of this conditional — // doing so would require the
              "manage" fields to be wrapped in a fragment */}
              <SummaryFieldsWrapper>
                {hookForm?.getValues('description') !== undefined && (
                  <EventDescriptionField
                    defaultValue={hookForm?.getValues('description')}
                    {...commonProps}
                  />
                )}
                <EventResourcesField {...commonProps} />
                <EventHostedByField {...commonProps} />
                <EventTypeField {...commonProps} />
              </SummaryFieldsWrapper>
            </>
          }
          Icon={Sparkle}
          title='Event basics'
        />
        <FormSection
          description='When is your event?'
          fields={<EventFormDateTimeFields isNewOrUpcoming {...commonProps} />}
          Icon={Clock}
          title='Time'
        />
        <FormSection
          description='Where is your event taking place?'
          fields={
            <EventLocationTypeField
              {...commonProps}
              eventId={eventId}
              meetingCreator={{ creatorId, creatorName, isSaved }}
            />
          }
          Icon={MapPin}
          title='Location'
        />

        <FormSection
          description={`Link ${
            eventBudgetFlagEnabled ? 'event expenses and ' : ''
          }analytics to groups`}
          fields={
            <SummaryFieldsWrapper>
              <EventGroupsField
                {...commonProps}
                // defaultValue={hookForm?.getValues('groupIds') || []}
              />
            </SummaryFieldsWrapper>
          }
          Icon={UsersThree}
          title='Groups'
        />
        <FormSection
          description='Manage the aspects of your event that happen behind the scenes'
          fields={
            <SummaryFieldsWrapper>
              <EventPrivacyField {...commonProps} />
              <EventOrganizerField {...commonProps} />
              <EventOfficesField {...commonProps} />
              {isPublished ? null : (
                <EventRsvpPausingField
                  defaultValue={
                    hookForm?.getValues('rsvpPauseAllowed') || false
                  }
                  {...commonProps}
                />
              )}
            </SummaryFieldsWrapper>
          }
          Icon={Gear}
          title='Settings'
        />
      </CardContent>
    </Card>
  );
};

export default EventForm;
